'use strict'
import $ from 'jquery'
import 'slick-carousel'
import objectFitImages from 'object-fit-images'

const body = document.querySelector('body')
const hamburgerWrapper = document.getElementById('js-hamburger')
const hamburger = hamburgerWrapper.querySelector('.Hamburger')
const spMenu = document.getElementById('js-menu')
const sp_width = 769
const window_width = window.innerWidth
const window_height = window.innerHeight
const ua = navigator.userAgent
const isiPhone = ua.indexOf('iPhone') > -1
const isiPad = ua.indexOf('iPad') > -1
const isAndroid = ua.indexOf('Android') > -1 && ua.indexOf('Mobile') > -1
const isAndroidTabvar = ua.indexOf('android') > -1 && ua.indexOf('Mobile') == -1
let scrollTop_hum;


// ページ表示しだい
window.addEventListener('DOMContentLoaded', () => {
  objectFitImages();
});

// ソースファイル読み完了次第
window.addEventListener('load', () => {
  loading();
});

const loading = ()=>{
  // const urlHash = location.hash
  // if (!urlHash) {
  //   $('html,body').animate({ scrollTop: 0 }, '1', function() {
  //     body.classList.add('is--load') // ページ表示
  //   })
  // } else {
    body.classList.add('is-load') // ページ表示
  // }
};


// $('.GlobalHeader__navLink').on('click', () => {
//   var sp_width = 769
//   window_width = window.innerWidth
//   if (window_width < sp_width) {
//     if (hamburger.classList.contains('is--active')) {
//       hamburger.classList.remove('is--active')
//       spMenu.classList.remove('is--open')
//       body.classList.remove('is--openMenu')

//       // -- 背景のスクロール禁止 --
//       body.style.top = ''
//       body.classList.remove('is--fixed')
//       window.scrollTo(0, scrollTop_hum)
//     }
//   }
// })

// ----- ハンバーガーメニュー -----
hamburgerWrapper.addEventListener('click', () => {

  if (!hamburger.classList.contains('is-active')) {
    hamburger.classList.add('is-active')
    if (!hamburger.classList.contains('is-on')) {
      hamburger.classList.add('is-on')
    }
    spMenu.classList.add('is-open')
    body.classList.add('is-openMenu')

    // -- 背景のスクロール禁止 --
    scrollTop_hum =
      document.documentElement.scrollTop || document.body.scrollTop
    body.classList.add('is-fixed')
    body.style.top = -scrollTop_hum + 'px'
  } else {
    hamburger.classList.remove('is-active')
    spMenu.classList.remove('is-open')
    body.classList.remove('is-openMenu')

    // -- 背景のスクロール禁止 --
    body.style.top = ''
    body.classList.remove('is-fixed')
    window.scrollTo(0, scrollTop_hum)
  }
})

// ----------  スクロールフェード  ----------
// function scrollAddClassCommon() {
//   // console.log('scroll')
//   if ($('.is-load').length) {
//     // console.log('scroll2')
//     $('.fadeinup').each(function() {
//       var target_pos = $(this).offset().top
//       var target_height = $(this).height()
//       var scroll = $(window).scrollTop()
//       window_height = window.innerHeight
//       // if (scroll > target_pos - windowHeight + target_height/5){
//       if (scroll > target_pos - window_height + target_height / 2) {
//         $(this).addClass('fadeInUp')
//       } else {
//         // $(this).removeClass('is--on');
//       }
//     })
//   }
// }

// ---------- グローバルナビ アクティブ ----------
function gnavCurrent() {
  var pathname = location.pathname.split('/')[1]
  var navList = $('.GlobalHeader__navItem')

  // console.log(pathname)

  // 各aタグに対して設定
  navList.each(function() {
    var href = $(this)
      .find('a')
      .attr('href')
    var _href1 = href.split('/')[1]
    console.log(_href1)
    if (pathname != '' && _href1 == pathname) {
      $(this)
        .find('a')
        .addClass('is-current')
    }
  })

  // var pathname2 = location.pathname.split('/')[3]
  // var newsNavList = $('.newsNav__listItem')
  // if (newsNavList.length) {
  //   newsNavList.each(function() {
  //     var href2 = $(this)
  //       .find('a')
  //       .attr('href')
  //     var _href2 = href2.split('/')[5]
  //     if (_href2 == pathname2) {
  //       $(this)
  //         .find('a')
  //         .addClass('is--active')
  //     }
  //   })
  // }
}

// ---------- スマホの場合のフルスクリーンの高さ調整 ----------
// function spFullscreenCommon() {
//   var window_height = window.innerHeight
//   // スライド画像の高さ
//   var globalHeaderNav = document.querySelector('.globalHeader__nav')
//   globalHeaderNav.style.height = window_height + 'px'
// }

// ---------- スムーススクロール ----------
// $('a[href^="#"]').click(function() {
//   var speed = 500
//   var href = $(this).attr('href')
//   var target = $(href == '#' || href == '' ? 'html' : href)
//   var headerHeight = $('.globalHeader').height()
//   var position = target.offset().top - headerHeight

//   $('html, body').animate({ scrollTop: position }, speed, 'swing')
//   return false
// })



// function noScroll(event) {
//   event.preventDefault();
// }
// // スクロール禁止(SP)
// document.addEventListener('touchmove', noScroll, { passive: false });
// // スクロール禁止(PC)

// document.addEventListener('mousewheel', noScroll, { passive: false });
// // スクロール禁止を解除(SP)
// document.removeEventListener('touchmove', noScroll, { passive: false });
// // スクロール禁止を解除(PC)
// document.removeEventListener('mousewheel', noScroll, { passive: false });